var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-label-text", {
                      attrs: { title: "순번", value: _vm.sopStep.jobStepNo },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10",
                  },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "작업순서",
                        value: _vm.sopStep.jobStepName,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "유해위험요인",
              isTitle: true,
              columnSetting: false,
              usePaging: false,
              filtering: false,
              gridHeightAuto: true,
              hideBottom: true,
              merge: _vm.grid.merge,
              columns: _vm.grid.columns,
              data: _vm.sopStep.sopHazardFactors,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "item"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${props.row["ramRiskHazardClassName"]} / ${props.row["riskHazardName"]}`
                              ) +
                              " "
                          ),
                        ]
                      : [
                          _c("q-btn", {
                            attrs: {
                              flat: "",
                              size: "12px",
                              color: "orange",
                              icon: "chevron_right",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.rowClickDetail(
                                  props.row,
                                  props.pageIndex
                                )
                              },
                            },
                          }),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.riskHazardName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("원인"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.hazardOccurReason),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("결과"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.hazardOccurResult),
                  },
                }),
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("유해위험요인 관련사진"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload-picture", {
                      attrs: {
                        height: "300px",
                        attachInfo: _vm.attachInfo,
                        editable: false,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }